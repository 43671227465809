import * as Yup from 'yup';

import { ReactNode, useRef, useState } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import { StyledButton } from '../styledButton/StyledButton';
import { InitialValues, RegistrationField, ValidationConfig, ValidationTypes } from '../../Interfaces';
import { Field } from '../Fields/Field';
import { buildYupStringSchema, fetchData } from '../../Helpers';
import { useRegistrationData } from '../../hooks/useRegistrationData';
import { Element, scroller } from 'react-scroll';
import React from 'react';
import ScrollContainer from '../Fields/ScrollContainer';
import { HotelInfo, BookingInfo, BusInfo, LegalBlock } from './Content';

const mapFieldToInitialValue = (type: string) => {
    switch (type) {
        case 'checkbox':
            return false;
        default:
            return '';
    }
}

const hasCustomYupString = (registrationField: RegistrationField) => {
    const customYupStrings = [
        {
            id: 37,
            validationString: Yup.string().when('field70', {
                is: (value: boolean) => { return value === false },
                then: (schema) => schema.required('Pflichtfeld')
            })
        }
    ]
    return customYupStrings.find(customYupString => customYupString.id === registrationField.id)
}

const buildInitialValuesAndValidationSchema = (registrationFields: RegistrationField[]) => {
    const initialValues: InitialValues = {};
    const validationSchema: ValidationConfig<any> = {};

    registrationFields.forEach((registrationField) => {
        if (registrationField.type !== 'text') {
            const fieldName = 'field' + registrationField.id;

            initialValues[fieldName] = mapFieldToInitialValue(registrationField.type);

            const customYupStringSchema = hasCustomYupString(registrationField);

            if (customYupStringSchema) {
                validationSchema[fieldName] = customYupStringSchema.validationString;
            } else {
                if (registrationField.required && typeof registrationField.parentValue === 'boolean') {
                    const parentField = 'field' + registrationField.parentField;
                    validationSchema[fieldName] = Yup.string().when(parentField, {
                        is: (value: boolean) => { return value === true },
                        then: (schema) => schema.required('Pflichtfeld')
                    });
                } else {
                    const fieldValidationSchema: ValidationTypes = {
                        required: registrationField.required,
                        type: registrationField.type,
                        min: registrationField.min,
                        max: registrationField.max
                    };

                    validationSchema[fieldName] = buildYupStringSchema(fieldValidationSchema);
                }
            }

        }
    });

    // Legal field ...
    validationSchema['legalRead'] = Yup.boolean().oneOf([true], 'Sie müssen die Bedingungen lesen und ihnen zustimmen')
    initialValues['legalRead'] = false;
    // END legal field

    return { initialValues, validationSchema };
};


const shouldRenderField = (registrationField: RegistrationField, values: Record<string, any>) => {
    if (registrationField.parentField) {
        if (typeof registrationField.parentValue !== 'undefined') {
            return values[`field${registrationField.parentField}`] === registrationField.parentValue;
        } else {
            return values[`field${registrationField.parentField}`] !== '';
        }
    }
    return true;
};

const getFieldOptions = (registrationField: RegistrationField) => {
    const defaultLanguage = registrationField.defaultLanguage;
    const options = registrationField.translations[defaultLanguage].options;

    return (
        options && Array.isArray(options)
            ? options.map((option: string) => ({ value: option, label: option }))
            : []
    );
};

const getTitle = (registrationField: RegistrationField) => {
    const defaultLanguage = registrationField.defaultLanguage;
    return registrationField.translations[defaultLanguage].title
}

const RegistrationForm = (props: { reset: boolean, anchorName: string }) => {

    const { anchorName, reset } = props;

    const scrollToElement = () => {
        scroller.scrollTo(anchorName, {
            duration: 5000
        });
    }

    const eventId = '9';
    const [isSynced, setIsSynced] = useState<boolean>(false);
    //const [isSynced, setIsSynced] = useState<boolean>(true);
    const [eventLanguage, setEventLanguage] = useState('');
    const { registrationFields, dataFetched } = useRegistrationData(eventId);
    const { initialValues, validationSchema } = buildInitialValuesAndValidationSchema(registrationFields);

    const mapRegistrationFieldType = (type: string) => {
        const typeMappings = {
            'text': 'type',
            'checkbox': 'checkbox',
            'textfield': 'text',
            'emailfield': 'email',
            'radiobuttons': 'radio',
            'multivalues': 'multivalues'
        };
        return typeMappings[type as keyof typeof typeMappings] || 'text';
    };

    interface IdObject {
        fieldId: number;
        before?: JSX.Element;
        after?: JSX.Element;
        afterContainer?: boolean
    }

    const StyledContainer = (props: { children: ReactNode }) => {
        const { children } = props;
        return (
            <Paper elevation={0} sx={{ padding: '1rem 1rem 0 1rem', marginTop: '1rem' }}>
                {children}
            </Paper>
        )
    }

    const ids: IdObject[] = [{
        fieldId: 39,
        before: <HotelInfo />
    }, {
        fieldId: 40,
        before: <BookingInfo />
    }, {
        fieldId: 50,
        afterContainer: false,
        after: <BusInfo />
    }];

    const IdMapping = (id: number, key: 'before' | 'after', values: InitialValues): JSX.Element | null => {
        const foundId = ids.find((e) => e.fieldId === id && e.hasOwnProperty(key));

        if (foundId && foundId[key] && typeof foundId[key] !== 'undefined') {
            const cmp = foundId[key]!;
            const componentWithValues = React.cloneElement(cmp, { values: values });

            if (foundId.afterContainer === false) {
                return componentWithValues;
            } else {
                return <StyledContainer>{componentWithValues}</StyledContainer>;
            }

        } else {
            return null;
        }
    };

    const BeforeContent = ({ id, values }: { id: number, values: InitialValues }) => {
        return IdMapping(id, 'before', values);
    };

    const AfterContent = ({ id, values }: { id: number, values: InitialValues }) => {
        return IdMapping(id, 'after', values);
    };

    const LegalContainer = () => {
        return (
            <Paper elevation={2} sx={{ padding: '1rem' }}>
                <Grid item md={12} xs={12}>
                    <Typography variant={'h3'} sx={{ margin: 0, fontWeight: 'bold', padding: '1rem 0 0 0' }} textAlign={'left'}>
                        Konditionen
                    </Typography>
                </Grid>
                <ScrollContainer noScroll labelText={'Ich habe die Bedingungen gelesen und bin damit einverstanden'} fieldName={'legalRead'}>
                    <LegalBlock
                        title="Anmeldevoraussetzungen"
                        text="Eine Vereinbarung mit der Apella AG oder Apella WertpapierService GmbH ist Voraussetzung für die Anmeldung. Für Vertriebspartner, die seit mindestens zwei Jahren mit Apella zusammenarbeiten, setzen wir eine aktive Tätigkeit (Courtageumsatz 2023) voraus."
                    />
                    <LegalBlock
                        title="Seminarreise"
                        text="Die Seminarreise hat einen Wert von 1.500,00 EUR (zzgl. MwSt.) pro Person. Nehmen Sie an allen Vorträgen der Gesellschaften teil, reduziert sich Ihr persönlicher Anteil auf den von Ihnen ausgewählten  Zimmerzuschlag. Diese Reduzierung ergibt sich aus der anteiligen Kostenübernahme durch die Apella AG."
                    />
                    <LegalBlock
                        title="Einwilligung Corona-Maßnahmen"
                        text="Der Unterzeichner erklärt sein Einverständnis mit den coronabedingten Maßnahmen des Veranstalters und des Betreibers des Veranstaltungsortes (Hotel u.ä.) während der Veranstaltung, insbesondere auf der Grundlage der vorgeschriebenen Hygienekonzepte. Zum Zeitpunkt der Veranstaltung gelten die aktuellen Corona-Regeln des Bundeslandes Mecklenburg-Vorpommern."
                    />
                    <LegalBlock
                        title="Rechnungslegung"
                        text="Eine Rechnung für die Übernachtungen erhalten Sie nach der Teilnahmebestätigung (Preis inkl. MwSt.)."
                    />
                    <LegalBlock
                        title="Nichtteilnahme"
                        text="Nur für den Fall der Nichtteilnahme am Jahresauftaktkongress ohne vorherige schriftliche Absage bis 14 Tage vor der Veranstaltung oder bei der Nichtteilnahme an den obligatorischen Veranstaltungsteilen (SalesRunden, Messe, Kongress), bin ich damit einverstanden, dass mir die Stornogebühren für die Seminartage in Höhe von 1.500,00 EUR zzgl. MwSt. in Rechnung gestellt werden. Mir ist bekannt, dass es in meiner Verantwortung liegt, dass ich bei den obligatorischen Veranstaltungsteilen registriert werde."
                    />
                    <LegalBlock
                        title="Einwilligungserklärung zur Nutzung von Fotoaufnahmen"
                        text="Der Unterzeichner erklärt sein Einverständnis mit der (unentgeltlichen) Verwendung der fotografischen Aufnahmen seiner Person während der
                                    o. g. Veranstaltung für die Veröffentlichung im Intranet der Apella AG und der Tochtergesellschaften, im Internet (soziale Medien), in Printmedien und sonstigen Medien für das Marketing der Apella AG
                                    und der verbundenen Unternehmen. Eine Verwendung der fotografischen Aufnahmen für andere als die beschriebenen Zwecke oder ein Inverkehrbringen durch Überlassung der Aufnahmen an Dritte ist unzulässig. Diese Einwilligung ist freiwillig. Wird sie nicht erteilt, entstehen keine Nachteile. Diese Einwilligung kann jederzeit mit Wirkung für die Zukunft widerrufen werden und gilt ansonsten unbeschränkt."
                    />
                </ScrollContainer>
            </Paper>
        )
    }

    const Loader = () => {
        return (
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <Box
                    component="img"
                    width="auto"
                    height="auto"
                    src="./images/spinner.gif"
                    alt="spinner"
                    mr={2}
                ></Box>
                <Typography fontSize={"24px"} fontWeight="normal" color="white">Laden des Formulars</Typography></Box>
        )
    }

    return (
        <>
            <Element name={anchorName}></Element>
            {!dataFetched && (
                <Loader />
            )}
            {dataFetched && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object(validationSchema)}
                    validateOnBlur={true}
                    onSubmit={(values, { setSubmitting }) => {

                        console.log('values', values);
                        const response = {
                            eventId: 9,
                            attending: true,
                            registrationfieldValues: values
                        }

                        //const url = '/api/registrations/'
                        const url = 'https://prod.clicla.me/api/registrations/'

                        fetchData('POST', url, (serverResponse: any) => {
                            setSubmitting(false);
                            setIsSynced(true);
                            scrollToElement();
                        }, response);
                    }}
                >
                    {({ values, resetForm, isValid }) => {
                        if (isSynced) {
                            return (<Paper sx={{ padding: '1rem', paddingTop: '2rem' }}><Box display="flex" alignContent={"center"} textAlign="center" flexDirection={"column"}>
                                <Box mb={2}>
                                    <Typography mb={2} variant={'h2'}>Wir haben Ihre Anmeldung erhalten!</Typography>
                                    <Typography mb={2} variant={'body1'}>Hiermit bestätigen wir den erfolgreichen Eingang Ihrer Anmeldung zum Apella Jahresauftaktkongress 2024. Vielen Dank für Ihr Interesse an unserer Veranstaltung.</Typography>
                                    <Typography mb={2} variant={'body1'}>Unsere Teams sind derzeit dabei, alle eingegangenen Anmeldungen auf Vollständigkeit und Erfüllung der Voraussetzungen zu prüfen. Nach Abschluss dieses Prozesses werden wir uns in Kürze erneut mit einer verbindlichen Zusage oder gegebenenfalls weiteren Informationen an Sie wenden.</Typography>
                                    <Typography mb={2} variant={'body1'}>Wir schätzen Ihre Teilnahme und freuen uns auf eine erfolgreiche Veranstaltung im Jahr 2024. Bei Fragen oder Anliegen stehen wir Ihnen gerne zur Verfügung.</Typography>
                                </Box>
                                {reset && <Box>
                                    <StyledButton onClick={() => {
                                        resetForm();
                                        setIsSynced(false);
                                    }}>eine weitere Registrierung</StyledButton></Box>}
                            </Box></Paper>)
                        } else {
                            return (
                                <Form noValidate>
                                    <Grid container justifyContent={'center'}>
                                        {registrationFields && registrationFields.map((registrationField, index) => {

                                            const shouldRender = shouldRenderField(registrationField, values);

                                            if (!shouldRender) {
                                                values['field' + registrationField.id] = ''; // Clear the value
                                            }

                                            return shouldRender ? (
                                                <Grid item xs={12} key={index}>
                                                    <Field
                                                        fieldId={registrationField.id}
                                                        label={registrationField.translations[eventLanguage || registrationField.defaultLanguage].title}
                                                        name={'field' + registrationField.id.toString()}
                                                        type={mapRegistrationFieldType(registrationField.type)}
                                                        title={getTitle(registrationField)}
                                                        options={getFieldOptions(registrationField)}
                                                        beforeContent={<BeforeContent id={registrationField.id} values={values} />}
                                                        afterContent={<AfterContent id={registrationField.id} values={values} />}
                                                        required={registrationField.required}
                                                    />
                                                </Grid>
                                            ) : null; // Render null if shouldRender is false
                                        })}
                                        <Grid item mb={2}>
                                            <LegalContainer />
                                        </Grid>
                                        <StyledButton type="submit">
                                            <Typography variant="body1">Anmelden</Typography>
                                        </StyledButton>
                                    </Grid>
                                </Form>
                            )
                        }
                    }}
                </Formik>
            )}
        </>
    )
}

export default RegistrationForm;