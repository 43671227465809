import { Grid, Typography, Box } from "@mui/material";

export const TypographyField = (props: { title: string | undefined }) => {
    const { title } = props;
    
    return (
        title !== undefined ? (
                <Box m={'1rem 0'} p={0}>
                    <Typography  m={0} color="common.white" variant={'h3'}>{title}</Typography>
                </Box>
        ) : null
    );
};
