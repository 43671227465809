import * as Yup from 'yup';
import { useTheme, Palette } from '@mui/material';
import { RegistrationField, ValidationTypes } from './Interfaces';

export const useColorFromThemePalette = (colorName: keyof Palette | undefined, defaultColor: string) => {
  const theme = useTheme();
  let color = defaultColor;
  if (colorName && colorName in theme.palette) {
    const paletteColor = theme.palette[colorName] as { main: string };
    color = paletteColor.main;
  }
  return color;
}

export const fetchData = async (method: string, apiUrl: string, successCallback: Function, requestBody?: any) => {
  const requestOptions = {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
      // Add other headers if needed
    },
    body: JSON.stringify(requestBody), // Only include a body for POST requests
  };
 

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const serverResponse = await response.json();

    if (serverResponse) {
        successCallback(serverResponse);
    } else {
      throw new Error('Server response indicates failure');
    }
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};

export const processServerData = async (serverResponse: any, registrationSetFunction: Function, /*blocksSetFunction: Function, filteredActivitiesSetFunction: Function*/) => {
  const { data } = serverResponse;
  const updatedRegistrationFields = overwriteServerData(data);

  await Promise.all([
    registrationSetFunction(updatedRegistrationFields.sort((a: RegistrationField, b: RegistrationField) => a.sortorder - b.sortorder)),
    //blocksSetFunction(data.agenda.filter((item: IRecord) => item.activity === false)),
    //filteredActivitiesSetFunction(data.agenda.filter((item: IRecord) => item.blockId !== null)),
  ]);
}

export const buildYupStringSchema = (config: ValidationTypes): any => {
  const {
    required = false,
    requiredText = 'pflichtfeld',
    max,
    type,
    emailText = 'ungültige E-Mail-Adresse',
    min,
  } = config;

  const maxText = typeof max === 'number' ? `Bitte wählen Sie maximal ${max} Aktivitäten aus` : 'Bitte wählen Sie maximal 2 Aktivitäten aus';
  const minText = typeof max === 'number' ? `Bitte wählen Sie mindestens ${min} Aktivitäten aus` :  'Bitte wählen Sie mindestens 2 Aktivitäten aus'
  let schema;

  
  if (type === 'multivalues') {
    schema = Yup.array();
  } else {
    schema = Yup.string();

    if (type === 'emailfield') {
      schema = schema.email(emailText)
    }
  }

  if (required) {
    schema = schema.required(requiredText)
  }

  if (max) {
    schema = schema.max(max, maxText);
  }

  if (min) {
    schema = schema.min(min, minText)
  }

  //Override everything if checkbox
  if (type === 'checkbox') {
    schema = Yup.boolean()
  }

  return schema;
};


const overwriteServerData = (data: any) => {
  //Event overrides to accomodate dependant validation
  const updatedRegistrationFields = [...data.registrationfields.map((regField: RegistrationField) => {
    let updatedRegField = regField;

    if (updatedRegField.id === 37) {
      updatedRegField = {
        ...regField,
        required: true,
        parentField: 70,
        parentValue: false
      }
    }
 
    if (updatedRegField.id === 44) {
      updatedRegField = {
        ...regField,
        required: true,
        parentField: 43,
        parentValue: true
      }
    }
    if (updatedRegField.id === 40) {
      updatedRegField = {
        ...regField,
        //required: true,
        parentField: 39
      }
    }
    if (updatedRegField.id === 41) {
      updatedRegField = {
        ...regField,
        parentField: 40,
        parentValue: 'Doppelzimmer'
      }
    }
    if (updatedRegField.id === 42) {
      updatedRegField = {
        ...regField,
        parentField: 40,
        parentValue: 'Doppelzimmer'
      }
    }

    if (updatedRegField.id === 43) {
      updatedRegField = {
        ...regField,
        parentField: 70,
        parentValue: false
      }
    }
    

    return updatedRegField;
    
  })]
  return updatedRegistrationFields;
}
