import { useEffect, useState } from 'react';
import { fetchData, processServerData } from '../Helpers';
import { RegistrationField } from '../Interfaces';

const url = 'https://prod.clicla.me/api/tempwebsite/';
//const url = '/api/tempwebsite/';

export const useRegistrationData = (eventId: string) => {
    const [registrationFields, setRegistrationFields] = useState<RegistrationField[]>([]);
    //const [blocks, setBlocks] = useState<IRecord[]>([]);
    //const [filteredActivities, setFilteredActivities] = useState<SessionRecord[]>([]);
    const [dataFetched, setDataFetched] = useState<boolean>(false);

    useEffect(() => {
        fetchData('GET', url + eventId, (serverResponse: any) => {
                processServerData(serverResponse, setRegistrationFields, /*setBlocks, setFilteredActivities*/)
                    .then(() => {
                        setDataFetched(true);
                    })
                    .catch((error) => {
                        console.error('Error processing data:', error);
                    });
        });
    }, []);

    return { registrationFields, dataFetched, /*blocks, filteredActivities,*/ };
};