import { useField } from "formik";
import { MyTextInputProps } from "../../Interfaces";
import { Grid, TextField } from "@mui/material";

export const TextInput = (props: MyTextInputProps) => {
    const { label, ...inputProps } = props;
    const [field, meta] = useField(inputProps);
    return (
            <TextField
                sx={[{ input: { borderRadius: '2px', backgroundColor: 'common.white' } }]}
                label={label}
                variant="filled"
                className="text-input"
                fullWidth
                {...field}
                {...inputProps}
                error={!!(meta.touched && meta.error)}
                helperText={meta.touched && meta.error ? meta.error : ''}
            />
    );
};