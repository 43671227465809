import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid } from "@mui/material";
import { ReactNode, useRef, useState, useEffect } from "react";
import { useField, } from "formik";

const ScrollContainer = ({ noScroll, labelText, fieldName, children }: { noScroll: boolean, labelText: string, fieldName: string, children: ReactNode }) => {
  const [scrolledToBottom, setScrolledToBottom] = useState<boolean>(false);
  const gridRef = useRef<HTMLDivElement | null>(null);
  const [field, meta] = useField(fieldName);
  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const gridContainer = gridRef.current;
    if (gridContainer) {
      if (gridContainer.scrollTop + gridContainer.clientHeight >= gridContainer.scrollHeight) {
        setScrolledToBottom(true);
      }
    }
  };

  useEffect(() => {
    if (noScroll) {
      setScrolledToBottom(true)
    }
  },[])


  return (
    <>
      <Grid item ref={gridRef} onScroll={handleScroll} container sx={{ maxHeight: '200px', overflow: 'scroll', paddingBottom: '1rem' }}>
        {children}
      </Grid>
      <FormControl error={!!(meta.touched && meta.error)}>
        <FormControlLabel control={<Checkbox {...field} disabled={!scrolledToBottom} />} label={labelText} />
        {meta.error && <FormHelperText sx={{margin: 0}}>{meta.error}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default ScrollContainer;