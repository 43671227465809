import { useField } from "formik";
import { MyCheckBoxInputProps } from "../../Interfaces";
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Paper, Typography } from "@mui/material";

export const MyCheckBox = (props: MyCheckBoxInputProps) => {
    const { label, description, beforeContent, afterContent, ...inputProps } = props;
    const [field, meta] = useField(inputProps);
    return (

        <Paper>
            {beforeContent}
            <Box sx={{ padding: '1rem' }}>
                <FormControl error={!!(meta.touched && meta.error)}>
                    {description && <Typography variant="body1">{description}</Typography>}
                    <FormControlLabel control={<Checkbox {...field} />} label={<FormLabel sx={{ color: 'var(--color-primary)'}}>{label}</FormLabel>} />
                    {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
                </FormControl>
            </Box>
            {afterContent}
        </Paper>
    )
}
