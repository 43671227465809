import { Box, Grid, Link, Paper, Typography } from "@mui/material"
import { InitialValues } from "../../Interfaces"

export const HotelBox = ({ imgSrc, title, bodyText }: { imgSrc: string, title: string, bodyText: string }) => {
    return (
        <Grid item md={6} xs={12}>
            <Paper elevation={2} >
                <Box
                    component="img"
                    style={{ width: '100%', objectFit: 'cover', height: '200px' }}
                    src={imgSrc}
                    alt={'hotel 1'}
                    mb={'1.5rem'}
                />
                <Box sx={{ padding: '1rem', paddingTop: 0 }}>
                    <Typography variant={'h3'} sx={{ margin: 0, marginBottom: '1.5rem' }} textAlign={'center'}>
                        {title}
                    </Typography>
                    <Typography variant={'body1'}>
                        {bodyText}
                    </Typography>
                </Box>
            </Paper>
        </Grid>
    )
}

export const RoomBox = ({ imgSrc, title, bodyText }: { imgSrc: string, title: string, bodyText: string }) => {
    return (
        <Grid item md={6} xs={12}>
            <Paper elevation={2} >
                <Box sx={{ padding: '1rem' }}>
                    <Typography variant={'h3'} sx={{ margin: 0 }} textAlign={'center'}>
                        {title}
                    </Typography>
                </Box>
                <Box
                    component="img"
                    style={{ width: '100%', objectFit: 'cover', height: 'auto' }}
                    src={imgSrc}
                    alt={'hotel 1'}
                />
                <Box sx={{ padding: '1rem' }}>
                    <Typography variant={'body1'} dangerouslySetInnerHTML={{ __html: bodyText }} />
                </Box>

            </Paper>
        </Grid>
    )
}

export const HotelInfo = () => {
    return (
        <Grid container spacing={2}>
            {/*}<Grid item xs={12}>
                <Typography variant="h3" sx={{margin: 0}}>
                    Übernachtung
                </Typography>
            </Grid>*/}
            <Grid item md={6} xs={12}>
                <Paper elevation={2} >
                    <Box
                        component="img"
                        style={{ width: '100%', objectFit: 'cover', height: '200px' }}
                        src={'./images/beechresort.webp'}
                        alt={'hotel 1'}
                        mb={'1.5rem'}
                    />
                    <Box sx={{ padding: '1rem', paddingTop: 0 }}>
                        <Typography variant={'h3'} sx={{ margin: 0, marginBottom: '1.5rem' }} textAlign={'center'}>
                            BEECH Resort
                        </Typography>
                        <Typography variant={'body1'}>
                            Erleben Sie Komfort im BEECH Resort: Moderne Apartments zu folgenden Konditionten:<br/>
                            Doppelzimmer (30,- € p.P. pro Übernachtung für 5 Nächte)<br/>
                            Einzelzimmer (55,- € p.P. pro Übernachtung für 5 Nächte)
                        </Typography>
                    </Box>
                </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
                <Paper elevation={2} >
                    <Box
                        component="img"
                        style={{ width: '100%', objectFit: 'cover', height: '200px' }}
                        src={'./images/schloss.webp'}
                        alt={'hotel 1'}
                        mb={'1.5rem'}
                    />
                    <Box sx={{ padding: '1rem', paddingTop: 0 }}>
                        <Typography variant={'h3'} sx={{ margin: 0, marginBottom: '1.5rem' }} textAlign={'center'}>
                            SCHLOSS Hotel
                        </Typography>
                        <Typography variant={'body1'}>
                            Erleben Sie puren Luxus im SCHLOSS Hotel Fleesensee und genießen Sie 5 Sterne Highclass-Komfort zu folgenden Konditionen:<br/>
                            Doppelzimmer (50,- € p.P. pro Übernachtung für 5 Nächte)<br/>
                            Einzelzimmer (95,- € p.P. pro Übernachtung für 5 Nächte)
                        </Typography>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}

export const RoomInfo = ({ values }: { values?: InitialValues }) => {
    if (values) {
        if (values.field39 === 'SCHLOSS Hotel Fleesensee') {
            return (<Grid container spacing={2}>
                <RoomBox
                    imgSrc={'./images/dubbelzimmer.webp'}
                    title="SCHLOSS Hotel Doppelzimmer"
                    bodyText="Übernachtung im Doppelzimmer (5 Nächte/50,- € p.P. pro ÜN)" />
                <RoomBox
                    imgSrc={'./images/dubbelzimmer.webp'}
                    title="SCHLOSS Hotel Einzelzimmer"
                    bodyText="Übernachtung im Einzelzimmer (5 Nächte/95,- € p.P. pro ÜN)" />
            </Grid>)
        } else if (values.field39 === 'BEECH Resort') {
            return (<>
                <Grid container>
                    <Grid item>
                        <Typography variant={'body1'} mb={2}><b style={{ color: 'red' }}>*</b>In einer Ferienwohnung stehen immer zwei Doppelzimmer zur Verfügung.
                            Nähere Informationen zu den Zimmeraufteilungen entnehmen Sie bitte der <Link color="secondary" sx={[{ textDecoration: 'none' }]} target="_blank" href="https://beechresort-fleesensee.com/apartments/#uebersichtS">Webseite</Link>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <RoomBox
                        imgSrc={'./images/Doppelzimmer.jpg'}
                        title="BEECH Resort Doppelzimmer"
                        bodyText="Übernachtung im Doppelzimmer in einer Ferienwohnung*<br/>(5 Nächte/30,- € p.P. pro ÜN)" />
                    <RoomBox
                        imgSrc={'./images/Einzelzimmer.jpg'}
                        title="BEECH Resort Einzelzimmer"
                        bodyText="Übernachtung im Einzelzimmer in einer Ferienwohnung*<br/>(5 Nächte / 55,- € p.P. pro ÜN)" />
                </Grid>
            </>)
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export const BookingInfo = ({ values }: { values?: InitialValues }) => {
    let hotel = '';
    if (values) {
        hotel = values.field39 === 'SCHLOSS Hotel Fleesensee' ? 'BEECH Resort' : 'SCHLOSS Hotel Fleesensee'
    }

    return (<>
        <Box><Typography variant={'body1'} mb={2}><b style={{ color: 'red' }}>*</b> Sollte das Kontingent EZ/DZ ausgebucht sein, bin ich mit EZ/DZ im {hotel} einverstanden.</Typography></Box>
        <RoomInfo values={values} />
    </>
    )
}

export const LegalBlock = ({ title, text }: { title: string, text: string }) => {
    return (<Grid item md={12} xs={12}>
        <Box sx={{ padding: '1rem 1rem 0 0' }}>
            <Typography variant={'body1'} sx={{ margin: 0, fontWeight: 'bold' }} textAlign={'left'}>
                {title}
            </Typography>
        </Box>
        <Box sx={{ padding: '0 1rem 0 0' }}>
            <Typography variant={'body1'}>
                {text}
            </Typography>
        </Box>
    </Grid>)

}

export const BusInfo = () => {
    return (<Box sx={{ padding: '1rem', paddingTop: 0, fontWeight: 'bold' }}>Bustransfer Berlin zurück: am 13.01.2024 – 09:00 Uhr vom „SCHLOSS Hotel Fleesensee“ und 09:15 Uhr vom „BEECH Resort“</Box>)
}