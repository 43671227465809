import React, { ReactNode, useState, useEffect } from 'react';
import './App.scss';
import { Paper, Box, Typography, Button, Grid, Palette, Link, ButtonProps, SxProps } from '@mui/material';

import { Link as ScrollLink } from 'react-scroll';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { partnerData } from './PartnerData.js';
import { useColorFromThemePalette } from './Helpers';
import Slider from './components/slider/Slider';
import RegistrationForm from './components/registrationForm/RegistrationForm';
import { scroller } from 'react-scroll';


const pageWidth = 1170;
const registrierungAnchor = "registrierungAnchor"

const Navigation = (props: { justifyContent: string, children: ReactNode }) => {
  const { justifyContent, children } = props;
  return (<Box sx={{ backgroundColor: "rgb(21,21,21)", padding: '0 1rem' }}>
    <Grid container justifyContent={justifyContent} sx={{ maxWidth: pageWidth + 'px', margin: '0 auto', padding: '1rem 0' }}>
      {children}
    </Grid>
  </Box>
  )
}

const ScrollIndicator = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [atBottom, setAtBottom] = useState(false);

  const indicatorHeight = 140;
  //console.log('atBottom', atBottom);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      if (((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight - indicatorHeight) && (pageWidth > window.innerWidth - indicatorHeight)) {
        setAtBottom(true)
      } else {
        setAtBottom(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Paper
      className={`scroll-indicator ${isVisible ? 'visible' : ''}`}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
      sx={{ marginBottom: atBottom ? '4.5rem' : '1rem' }}
    >
      <ScrollLink
        to="home"
        spy={true}
        smooth={true}
        duration={500}
      >
        <ArrowUpwardIcon />
      </ScrollLink>
    </Paper>
  );
};

const HeaderNavigation = () => {
  return (
    <Navigation justifyContent="space-between">
      <Grid item sm={true} xs={12} display="flex" sx={{ alignItems: { xs: 'center' }, marginBottom: { sm: '0', xs: '2rem' } }}>
        <img
          src="./images/apella.png"
          alt="Logo"
          style={{ maxWidth: '100%', maxHeight: '60px' }}
        />
      </Grid>
      <Grid item sm={true} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <nav>
          <ul>
            <li>
              <ScrollLink
                to="formate"
                spy={true}
                smooth={true}
                duration={500}
              >
                Formate
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="highlights"
                spy={true}
                smooth={true}
                duration={500}
              >
                Highlights
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="referenten"
                spy={true}
                smooth={true}
                duration={500}
              >
                Referenten
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="kontakt"
                spy={true}
                smooth={true}
                duration={500}
              >
                Kontakt
              </ScrollLink>
            </li>
          </ul>
        </nav>
      </Grid>
    </Navigation>
  );
}

const FooterNavigation = () => {
  return (
    <Navigation justifyContent="flex-end">
      <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
        <nav>
          <ul>
            <li><a href="https://podcast.apella.de/impressum" target="_blank">Impressum</a></li>
            <li><a href="https://podcast.apella.de/datenschutzhinweise" target="_blank">Datenschutzhinweise</a></li>
          </ul>
        </nav>
      </Grid>
    </Navigation>
  );
}

interface StyledIconButtonProps extends ButtonProps {
  icon: React.ReactElement,
  text: string
}

const StyledIconButton = (props: StyledIconButtonProps) => {
  const { icon, text, ...restProps } = props;
  return (
    <Button variant="contained" color="secondary" sx={{ padding: '1rem', minWidth: '30%', fontSize: '1.5rem', textTransform: 'inherit' }} startIcon={icon} {...restProps}>
      {text}
    </Button>
  )
}


interface StyledBoxProps {
  imgSrc: string,
  title: string,
  md?: number,
  xs?: number,
  children: ReactNode
}

interface ContainedSectionProps {
  id?: string,
  children?: ReactNode,
  colorName?: keyof Palette,
  color?: string,
  slopedBottom?: boolean,
  slopedTop?: boolean
  sx?: SxProps,
  siblingHasSlopedBottom?: boolean
}

interface ContainedImgSectionProps {
  maxHeight?: number,
  img?: string,
  slopedBottom?: boolean,
  slopedTop?: boolean
}


const Hero = () => {
  return (
    <Box>
      {/* countdown timer */}
      {/*}<p>00 Tage</p>
      <p>00 Stunden</p>
      <p>00 Minuten</p>
    <p>00Sekunden</p>{*/}
      <Typography variant="h1" sx={{ hyphens: 'auto', wordBreak: 'break-word' }} color="common.white">Texte für die JAK 2024 Landings&shy;page</Typography>
      <img src="./images/hero-placeholder.jpg" alt="placeholder" />
    </Box>
  )
}


const StyledBox = (props: StyledBoxProps) => {
  const { imgSrc, title, md, xs, children } = props;
  return (
    <Grid item md={md} xs={xs} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: { xs: '2rem' } }}>
      <Box component="img" src={imgSrc} alt="test" sx={{ maxWidth: '150px', height: 'auto' }} />
      <Typography color="primary" variant="h3" >{title}</Typography>
      {children}
    </Grid>
  );
}

const ContainedSection = (props: ContainedSectionProps) => {
  const { id, color, colorName, slopedBottom, slopedTop, children, sx, siblingHasSlopedBottom } = props;
  const backgroundColor = useColorFromThemePalette(colorName, color || '');
  let className: string = 'containedSection ';
  if (slopedBottom) {
    className += 'slopedBottom '
  }
  if (slopedTop) {
    className += 'slopedTop '
  }

  //If last child had a slopedBottom - need to adjust the slopedtop so they overlap

  const gridSx = {
    position: 'relative',
    marginBottom: 0 as number | string,
    top: 0 as number | string,
  }

  if (siblingHasSlopedBottom) {
    gridSx.top = 'calc(-100vw * tan(var(--angle) / 2))';
    gridSx.marginBottom = 'calc(-100vw * tan(var(--angle) / 2))';
  }

  return (
    <Grid id={id} className={className} sx={{ ...gridSx }}>
      {slopedTop && (
        <Box sx={{ width: '100%', backgroundColor: backgroundColor, height: 'calc((100vw * tan(var(--angle)) / 2))' }} />
      )}
      <Grid container sx={{ backgroundColor: backgroundColor, padding: '4rem 1rem', ...sx }}>

        <Grid sx={{ maxWidth: pageWidth + 'px', width: '100%', padding: '0', margin: '0 auto' }}>
          {children}
        </Grid>

      </Grid>
      {slopedBottom && (
        <Box sx={{ width: '100%', backgroundColor: backgroundColor, height: 'calc(100vw*(tan(var(--angle))) / 2)' }} />
      )}
    </Grid>
  );
};

const ContainedImgSection = (props: ContainedImgSectionProps) => {
  const { img, maxHeight, slopedBottom, slopedTop } = props;
  let className: string = 'imgSection ';
  if (slopedBottom) {
    className += 'slopedBottom '
  }
  if (slopedTop) {
    className += 'slopedTop '
  }
  return (
    <Box className={className} sx={{ width: '100%', height: 'auto', backgroundImage: 'url("' + img + '")', backgroundPosition: 'center', backgroundSize: 'cover' }} />
  )
}

const SectionTitle = (props: { text: string, color?: string, extraText?: ReactNode }) => {
  const { text, color, extraText } = props;
  return (
    <Grid item md={12}>
      <Typography variant="h2" color={color} sx={{textAlign: "center", hyphens: 'auto', wordBreak: 'break-word', lineHeight: "4rem" }}>{text}</Typography>
      {extraText}
    </Grid>
  )
}

const StyledH3 = (props: { text: string, colorName?: keyof Palette; color?: string; }) => {
  const { text, colorName, color } = props;
  const myColor = useColorFromThemePalette(colorName, color || '');
  return (
    <>
      <Typography variant="h3" color={myColor} sx={{ marginLeft: 0 }} mb={'1rem'}>{text}</Typography>
      <Box sx={{ width: '20%', height: "2px", backgroundColor: myColor }} mb={'1rem'} />
    </>
  )
}
const ImageBox = (props: { title: string, imageSrc: string, altText: string, description: string }) => {
  const { title, imageSrc, altText, description } = props;

  return (
    <Grid item md={4} textAlign={"center"}>
      <Typography variant="h2" sx={{ fontSize: 25 / 16 + 'rem', fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Box
        component="img"
        style={{ maxWidth: '100%', height: '250px', objectFit: 'contain' }}
        src={imageSrc}
        alt={altText}
        mb={'1.5rem'}
      />

      <Typography variant="body1">{description}</Typography>
    </Grid>
  );
}

const BoxItem = (props: { children: ReactNode, colorName?: keyof Palette; color?: string; }) => {
  const { children, colorName, color } = props;
  const myColor = useColorFromThemePalette(colorName, color || '');
  return (
    <Grid item sx={{ boxSizing: 'border-box', padding: 25 / 16 + 'rem', backgroundColor: myColor }}>
      {children}
    </Grid>
  )
}

const BoxHero = (props: { title: string, videoSrc?: string, poster?: string, imageSrc?: string, altText: string, colorName?: keyof Palette; color?: string; }) => {
  const { title, imageSrc, videoSrc, altText, colorName, color, poster } = props;
  const myColor = useColorFromThemePalette(colorName, color || '');

  return (
    <Box display="flex" flexDirection="column">
      <Typography className="image-box-title" sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: 20 / 16 + 'rem', backgroundColor: myColor, color: 'common.white', padding: 20 / 16 + 'rem', marginBottom: '2px' }}>{title}</Typography>

      {
        videoSrc && (
          <Box
            component="video"
            sx={{ width: '100%' }}
            src={videoSrc}
            poster={poster}
            controls
          />
        )
      }
      {
        imageSrc && (
          <Box
            component='img'
            src={imageSrc}
            alt={altText}
            width="100%"
            height="auto"
          />
        )
      }
    </Box>
  );
}

const BoxContent = (props: { title: string, title2: string, children: ReactNode }) => {
  const { children, title, title2 } = props;
  return (
    <Box display="flex" flexDirection="column" textAlign="center" padding={'1rem'}>
      <Typography mb={'1rem'} sx={{ fontSize: '1rem', fontWeight: 'bold', lineHeight: '1.4rem' }}>{title}</Typography>
      <Typography mb={'1rem'} sx={{ fontSize: 24 / 16 + 'rem', fontWeight: 'bold', fontFamily: '"Cardo", sans-serif', lineHeight: '1.4rem', color: '#64817f' }}>{title2}</Typography>
      {children}
    </Box>
  )
}

const BoxContentText = (props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <Typography mb={'1rem'} sx={{ fontSize: '1rem', color: '#808080', lineHeight: '1.2rem' }}>{children}</Typography>
  )
}

const AnmeldenButton = () => {
  return <StyledIconButton icon={<KeyboardArrowRightIcon />} onClick={() => {
    scroller.scrollTo(registrierungAnchor, {
      duration: 500,
      delay: 0,
      smooth: true
    });
  }} text="Jetzt Anmelden!" />
}

const PersonBox = (props: { src: string, name: string, fn: string }) => {
  const { name, src, fn } = props;
  return (
    <Grid item md={2} sm={4} xs={6}>
      <Box className="person-box" display="flex" flexDirection={"column"} height="100%">
        <Box
          sx={{ backgroundColor: 'grey', display: 'flex', alignItems: 'center' }}
        >
          <Box
            component="img"
            alt={'image' + name}
            width="100%"
            height="auto"
            src={src}
          />
        </Box>
        <Box className="person" sx={{ flex: 1 }}>
          <Typography className="name">{name}</Typography>
          {/* THIS IS POTENTIALLY A BIG RISK WHEN THE SERVER COME FROM THE SERVER - SANITIZE FIRST BEFORE SETTING STUFF DANGEROUSLY */}
          <Typography className="function" sx={{ wordWrap: "break-word", hyphens: "auto" }} dangerouslySetInnerHTML={{ __html: fn }} />
        </Box>
      </Box>
    </Grid>
  )
}


function App() {

  return (
    <div id="home" className="App">
      <ScrollIndicator />
      <HeaderNavigation />
      {/*}<ContainedSection colorName="apellagreen" slopedBottom>
        <Hero />
      </ContainedSection>*/}
      <ContainedSection>
        <Grid container >
          <Grid item md={12} xs={12} textAlign={"center"} >
            <Typography variant="h1cardo" color="primary" sx={{ wordBreak: 'break-word', marginBottom: 0 }}>Der Apella Jahresauftaktkongress 2024</Typography>
            <Typography variant="h1cardo" color="primary" sx={{
              wordBreak: 'break-word', fontSize: {
                xs: '1.5rem',
                md: '2rem'
              }, marginBottom: '3rem'
            }}>Eine Veranstaltung - sechs abwechslungsreiche Formate</Typography>
          </Grid>
          <Grid container mb={'2rem'} alignItems={'flex-start'}>
            <StyledBox md={4} xs={12} imgSrc="./images/wo.png" title="WO?">
              <Typography variant="body1" color="primary" sx={{ fontSize: '1.25rem' }}>SCHLOSS Hotel Fleesensee<br />17213 Göhren-Lebbin</Typography>
            </StyledBox>
            <StyledBox md={4} xs={12} imgSrc="./images/wann.png" title="WANN?">
              <Typography variant="body1" color="primary" sx={{ fontSize: '1.25rem' }}>08.-13. Januar 2024</Typography>
            </StyledBox>
            <StyledBox md={4} xs={12} imgSrc="./images/was.png" title="WAS?">
              <Typography variant="body1" color="primary" sx={{ fontSize: '1.25rem' }}>Umfassendes Wissen für das ganze Geschäftsjahr in 5 Tagen sammeln und dafür bis zu 18 IDD Stunden erhalten</Typography>
            </StyledBox>
          </Grid>
          <Grid item md={12} xs={12} textAlign={"center"}>
            <AnmeldenButton />
          </Grid>
        </Grid>
      </ContainedSection>
      <ContainedSection id="formate" color="#f2f2f2" slopedBottom>
        <Grid container>
          <Grid item md={12} xs={12}>
            <Typography variant="h2" color={"primary"} textAlign={"center"} sx={{width: '100%', hyphens: 'auto', wordBreak: 'break-word', lineHeight: "4rem" }}>Vielfältige Weiterbildungs&shy;optionen<br />in abwechlungs&shy;reichen Formaten</Typography>
          </Grid>
          <SectionTitle color="primary" text="" />
          <Grid container>
            <Grid container spacing={3} mb={4}>
              <Grid item md={4}>
                <Box
                  component="img"
                  width="100%"
                  height="auto"
                  src="./images/powertag.jpg"
                  alt="alt"
                />
                <StyledH3 colorName="primary" text="POWERTAG" />
                <Typography variant="body2">Entdecken Sie die Zukunft des Maklergeschäfts auf unserem "Powertag". Erleben Sie spannende Einblicke in die neuesten Entwicklungen, Produkte und Tools der Apella AG, exklusiv für unsere angebundenen Makler. Seien Sie dabei und profitieren Sie für Ihre eigene Erfolgsgeschichte!</Typography>
              </Grid>
              <Grid item md={4}>
                <Box
                  component="img"
                  width="100%"
                  height="auto"
                  src="./images/Julius_Brink_2.jpeg"
                  alt="alt"
                />
                <StyledH3 colorName="primary" text="ABENDVORTRÄGE" />
                <Typography variant="body2">Erleben Sie inspirierende Abendvorträge mit prominenten Keynote-Speakern wie Olympiasieger Julius Brink. Thema: "Die Kraft der Niederlage - Erfolg durch Scheitern." Ein Abend voller Erkenntnisse und Motivation für Sie als Teilnehmer.</Typography>
              </Grid>
              <Grid item md={4}>
                <Box
                  component="img"
                  width="100%"
                  height="auto"
                  src="./images/HAF.jpg"
                  alt="alt"
                />
                <StyledH3 colorName="primary" text="HANS ABER FAIR" />
                <Typography variant="body2">Top-Versicherungsvorstände unter Prof. Dr. Hans-Wilhelm Zeidlers kritischer Lupe. Brisante Themen, ehemalige Zitate und aktuelle Einblicke. Ein Format, das Tiefgang und Transparenz in die Versicherungswelt bringt.</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <Box
                  component="img"
                  width="100%"
                  height="auto"
                  src="./images/salesrunden2.jpg"
                  alt="alt"
                />
                <StyledH3 colorName="primary" text="SALESRUNDEN" />
                <Typography variant="body2">In den 25-minütigen Salesrunden treffen Sie persönlich auf 18 Produktpartner aus dem Bereich Versicherungen, Kapitalanlagen, Fonds und Immobilien. Erfahren Sie alles für ein erfolgreiches Geschäftsjahr 2024 im intensiven Dialog.</Typography>
              </Grid>
              <Grid item md={4}>
                <Box
                  component="img"
                  width="100%"
                  height="auto"
                  src="./images/messe2.jpg"
                  alt="alt"
                />
                <StyledH3 colorName="primary" text="MESSE" />
                <Typography variant="body2">Der Messetag ist ein sehr erfolgreicher Teil innerhalb des „Jahresauftaktkongresses“ geworden. Das Messegeschehen wird durch die kurzweiligen „Pecha Kucha“-Vorträge sowie die vielfältigen Gespräche an den Messeständen der ca. 50 Partnergesellschaften geprägt.</Typography>
              </Grid>
              <Grid item md={4}>
                <Box
                  component="img"
                  width="100%"
                  height="auto"
                  src="./images/kongress.jpg"
                  alt="alt"
                />
                <StyledH3 colorName="primary" text="KONGRESS" />
                <Typography variant="body2">Top-Referenten, Vorstände, Geschäftsführer und Fondsmanager sprechen hier über aktuelle Branchenthemen. Podiumsdiskussionen zu Versicherungs- und Finanzmärkten inklusive.</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ContainedSection>

      <ContainedImgSection img={'./images/building.webp'} maxHeight={800} slopedTop slopedBottom />
      <ContainedSection color="#f2f2f2" id="highlights" slopedTop slopedBottom>
        <Grid container mt={'2rem'}>
          <SectionTitle text="Diese Highlights dürfen Sie nicht verpassen" />
        </Grid>

        <Grid container mb={3}>
          <Grid item md={6} xs={12} sx={{ backgroundColor: 'white' }}>
            <Box sx={{ padding: '1rem' }}>
              <BoxHero
                title="MESSE-VERLOSUNG"
                imageSrc="./images/Gewinn.jpg"
                altText="test"
                colorName="apellagreen"
              />
              <BoxContent title="6x Kopfhörer-Sets und 2x E-Roller Chopper" title2={'"Die Stars der Messe"'}>
                {/*}<BoxContentText>Die Messetage sind ein sehr erfolgreicher Teil des “Jahresauftaktkongresses” und finden 2024 hier wieder in bewährter Form statt.</BoxContentText>{*/}
                <BoxContentText>Das große Highlight der Messe ist die Verlosung. An unseren zwei Messetagen verlosen wir beim Jahresauftaktkongress 2024 zwei E-Roller Chopper im Wert von jeweils 2.500 € sowie jeweils sechs hochwertige Kopfhörer-Sets.</BoxContentText>
                {/*}<Typography sx={{ fontSize: 10 / 16 + 'rem', fontWeight: 'bold' }}>*Das moderne E-Auto wird Ihnen für zwei Jahre zur Nutzung zur Verfügung gestellt.</Typography>{*/}
              </BoxContent>
            </Box>
          </Grid>
          <Grid item md={6} xs={12} sx={{ backgroundColor: 'white' }}>
            <Box sx={{ padding: '1rem' }}>
              <BoxHero
                title="THEMENABENDE"
                imageSrc="./images/orangerie-downscale.jpg"
                altText="THEMENABENDE"
                colorName="apellagreen"
              />
              <BoxContent title="Abendpaket" title2={'"Themenabende in der Orangerie"'}>
                <BoxContentText>Lassen Sie sich kulinarisch verwöhnen!<br /><br />Am 09.01. genießen Sie "Sushi am Fleesensee"</BoxContentText>
                <BoxContentText>Am 10.01. steht "Mecklenburger Federvieh" auf dem Menü, gefolgt von "Wild aus eigener Jagd" am 11.01. – ein Fest für Feinschmecker!</BoxContentText>
              </BoxContent>
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={{ boxSizing: 'border-box', padding: 25 / 16 + 'rem', backgroundColor: 'common.white' }}>
          <Grid item md={6} xs={12}>
            <BoxHero
              title="ABSCHLUSSABEND"
              videoSrc="./video/gestoert.mp4"
              poster="./video/gestoert_poster.jpg"
              altText="THEMENABENDE"
              colorName="apellagreen"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <BoxContent title="Der Abschlussabend" title2={'“Gestört aber Geil” LIVE'}>
              <BoxContentText>Unser Höhepunkt ist der unvergessliche Abschluss einer ereignisreichen Woche! Das aus den Charts bekannte DJ-Duo "Gestört aber Geil" sorgt für den musikalischen Rausch, begleitet von einem mitreißenden Support Act. Das Motto der Veranstaltung <span style={{ fontWeight: 'bold' }}>"Follow Yellow"</span>.<br />Seien Sie Teil des Abends und verleihen Sie Ihrem Look mit einem gelben Accessoire das gewisse Etwas. Ein Abend voller Energie und musikalischer Exzellenz!</BoxContentText>
              <Box>
                <Typography sx={{ fontSize: 24 / 16 + 'rem', fontWeight: 'bold', color: '#808080' }}>Motto:</Typography>
                <Box
                  component="img"
                  src="./images/follow-yellow.jpg"
                  sx={{ maxWidth: '250px', height: 'auto' }}
                />
              </Box>
            </BoxContent>
          </Grid>
        </Grid>

      </ContainedSection>
      <ContainedSection id="referenten" color="#eee" slopedTop slopedBottom siblingHasSlopedBottom>
        <Grid container>
          <Grid container mb={'4.3rem'} flexDirection={"column"}>
            <Typography variant="h2" mb={0.5} textAlign={"center"}>Referenten und Keynote Speaker</Typography>
          </Grid>
          <Grid container alignItems="stretch" spacing={3}>
            <PersonBox
              src="./images/Birger_Dehne.jpg"
              name="Birger Dehne"
              fn="Gründer & CEO Birger Dehne AG"
            />
            <PersonBox
              src="./images/Julius_Brink.jpg"
              name="Julius Brink"
              fn="Beachvolleyballheld "
            />
            <PersonBox
              src="./images/Claus_Kriebel.jpg"
              name="Prof. Dr. Claus M. Kriebel"
              fn="der Geldprofessor"
            />
            <PersonBox
              src="./images/Maurice_Buonodono.jpg"
              name="Maurice Buonodono"
              fn="Leiter DSL Bank DE"
            />
            <PersonBox
              //src="./images/Martin_Grafer.jpg"
              src="./images/Martin_Grafer.jpg"
              name="Martin Gräfer"
              fn="Vorstand <br/>die Bayerische"
            />
            <PersonBox
              src="./images/Hans_Zeidler.jpg"
              name="Prof. Dr. Hans-Wilhelm Zeidler"
              fn="Aufsichtsrats&shy;vorsitzender Apella"
            />
            <PersonBox
              src="./images/Rolf_Wiswesser.jpg"
              name="Dr. Rolf Wiswesser"
              fn="Vorstand<br/>Allianz"
            />
            <PersonBox
              src="./images/Ralf_Berndt.jpg"
              name="Ralf Berndt"
              fn="Vorstand<br/>Stuttgarter "
            />
            <PersonBox
              src="./images/Eric_Bussert.jpg"
              name="Eric Bussert"
              fn="Vorstand<br/>HanseMerkur"
            />
            <PersonBox
              //src="./images/Hermann_Schrogenauer.jpg"
              src="./images/Hermann_Schrogenauer.jpg"
              name="Hermann Schrögenauer"
              fn="Vorstand LV1871"
            />
            <PersonBox
              src="./images/Frank_Lamsfuss.jpg"
              name="Frank Lamsfuß"
              fn="Vorstand<br/>Barmenia"
            />
            <PersonBox
              src="./images/Lutz_Harbig.jpg"
              name="Lutz Harbig"
              fn="Rechtsanwalt"
            />
          </Grid>
        </Grid>
      </ContainedSection >
      <ContainedSection>
        <SectionTitle text="Stimmen vom Jahresauftaktkongress 2023" />
        <Box
          component="video"
          sx={{ width: '100%' }}
          src="./video/feedback2023.mp4"
          poster="./video/feedback2023_poster.jpg"
          controls
          mb={2}
        />
      </ContainedSection>
      <ContainedSection color="#1f4034" slopedTop slopedBottom>
        <SectionTitle color="common.white" text="ANMELDEN & GEMEINSAM DURCHSTARTEN" />
        {/*}<Box display={"flex"} justifyContent={"center"} flexDirection={"column"}>
          <TextField variant="filled" label="E-mail Adresse" sx={[{ marginBottom: '1rem' }, { input: { borderRadius: '2px', backgroundColor: 'common.white' } }]} />
          <Box textAlign={"center"}>
            <StyledButton>
              <Box>
                <Typography variant="body1">Anmeldeformular anfordern</Typography><Typography variant="body2">Sie erhalten das Formular per Mail zum Download</Typography>
              </Box>
            </StyledButton>
          </Box>
  </Box>{*/}
        <Box>
          <RegistrationForm reset={false} anchorName={registrierungAnchor} />
        </Box>
      </ContainedSection>
      <ContainedSection id="kontakt">
        <Box>
          <Grid container sx={{ textAlign: 'center' }} flexDirection="column" mb={'2rem'} justifyContent={'center'}>
            <Typography variant="h1" color="primary" mb={'1rem'} sx={{ fontWeight: 'bold', textAlign: 'center' }}>KONTAKT</Typography>
            <Typography variant="h3" color="primary" sx={{ fontWeight: 'normal' }}>Sie haben Fragen zu unserem Jahresauftaktkongress 2024? <br />Treten Sie mit uns in Kontakt und wir helfen Ihnen gerne weiter.</Typography>
          </Grid>

          <Grid container display="flex" flexDirection="row" justifyContent={'center'} spacing={3}>
            <Grid item>
              <Box sx={{ maxWidth: '240px', height: 'auto', borderRadius: '50%' }}
                component="img"
                src="./kontaktperson.jpg"
                alt="Juliane Retlaff image"
              />
            </Grid>
            <Grid item display="flex" flexDirection="column" justifyContent={'center'}>
              <Typography variant="body1" mb={'1rem'} sx={{ fontWeight: 'bold', fontSize: '20px' }}>Juliane Retzlaff</Typography>
              <Typography variant="body1" mb={'1rem'} >E-Mail: <Link color="secondary" sx={[{ textDecoration: 'none' }]} href="mailto:jretzlaff@apella.de">jretzlaff@apella.de</Link></Typography>
              <Typography variant="body1" mb={'1rem'} >Telefon: <Link color="secondary" sx={{ textDecoration: 'none' }} href="tel:0395 571909-24">0395 571909-24</Link></Typography>
              <AnmeldenButton />
            </Grid>
          </Grid>
        </Box>
      </ContainedSection>
      {/*}<ContainedSection color="rgb(30, 30, 30)" slopedTop>
        <Box textAlign={"center"}>
          <Typography variant="h2" color="white" mb={'0.5rem'} sx={{hyphens: 'manual', wordBreak: 'break-word', fontWeight: 'bold'}}>Teilnehmende Partner&shy;gesellschaften</Typography>
          <Typography variant="h3" color="white" sx={{hyphens: 'manual', wordBreak: 'break-word', fontWeight: 'normal'}}>Folgende Partner&shy;gesellschaften sind 2024 dabei</Typography>
          
        </Box>
        <Slider maxWidth={pageWidth} slides={partnerData} />
            </ContainedSection>{*/}


      <FooterNavigation />
    </div>
  );
}

export default App;
