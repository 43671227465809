import { useField } from "formik";
import { MyRadioInputGroup } from "../../Interfaces";
import { StyledButton } from '../styledButton/StyledButton';
import { Box, Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Paper, Radio, RadioGroup, Typography } from "@mui/material";

export const RadioInputGroup = (props: MyRadioInputGroup) => {
    const { label, options, description, beforeContent, afterContent, required, ...inputProps } = props;
    const [field, meta, helpers] = useField(inputProps);
    return (
        
        <Paper>
            {beforeContent}
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '1rem' }}>
                <FormControl error={!!(meta.touched && meta.error)}>
                    <FormLabel sx={{ color: 'var(--color-primary)', fontWeight: 'bold' }}>{label}</FormLabel>
                    {description && <Typography variant="body1">{description}</Typography>}
                    <RadioGroup
                        row
                        {...field}
                    >
                        {options.map((option, index) => {
                            return <FormControlLabel sx={{padding: /<br\s*\/?>/.test(option.label) ? '1rem 0' : '0'}} control={<Radio />} key={index} value={option.value} label={
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: option.label
                                }}
                            />
                        } />})}
                    </RadioGroup>
                    {meta.error && meta.touched && <FormHelperText>{meta.error}</FormHelperText>}
                </FormControl>
                {!required && (
                    <StyledButton sx={{ padding: '0 1rem', fontSize: '1rem', minWidth: 'auto' }} onClick={() => {
                        helpers.setValue('');
                    }}>Zurücksetzen</StyledButton>
                )}
            </Box>
            {afterContent}
        </Paper>
    )
}
