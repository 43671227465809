import React, { ReactNode } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

interface StyledButtonProps extends ButtonProps {
  children: ReactNode;
}

export const StyledButton = (props: StyledButtonProps) => {
  const { children, ...rest } = props;
  return (
    <Button
      variant="contained"
      color="secondary"
      {...rest} 
      sx={{
        padding: '1rem',
        minWidth: '30%',
        fontSize: '1.5rem',
        textTransform: 'inherit',
        ...rest.sx
      }}
    >
      {children}
    </Button>
  );
};